.selected {
  background-color: var(--secondary--color-selected);
}
.collapsed-customer-dropdown {
  font-size: 14px;
  text-align: left;
  border-radius: 8px;
  height: 49px;
  margin: 0px 0px 14px 18px;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  width: 49px;
  display: flex;
  border: 1px solid var(--primary-color-dark);
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.default-customer-dropdown {
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  color: white;
  white-space: nowrap;
  font-size: 14px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--primary-color-dark);
  height: 49px;
  margin: 0;
  padding: 10px 15px;
  width: 100%;
  max-width: 160px;
  margin-left: 16px;
  margin-bottom: 36px;
  overflow: hidden;
  font-weight: bold;
  align-content: center;
  cursor: pointer;
}

.custom-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.avatar-name-wrapper {
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 35px;
  border-radius: 10px;
  padding: 6px;
}
.custom-dropdown-menu {
  background-color: var(--secondary--color-drawer) !important;
}

.custom-dropdown-create-customer {
  color: white;
  margin-top: 16px;
}

.avatar-name-wrapper .selected {
  background-color: var(--secondary--color-selected) !important;
}

.selected {
  background-color: var(--secondary--color-selected) !important;
}
