.search-input-header {
}

@media (min-width: 992px) and (max-width: 1999px) {
  .search-input-header {
    margin-left: 50px;
  }
}

.content-search-wrapper {
  border-radius: 50px;
  background-color: #e8e3e3;
  z-index: 9999;
  left: 500px;
  top: 100px;
  position: absolute;
  width: 400px;
  height: 40px;
}

.content-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.content-search-wrapper {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
