.button-collapse {
  border: 1px;
  background-color: red;
  right: -32px;
  bottom: 10px;
  position: absolute;
  color: white;
  font-size: 16px;
}

.banner {
  height: 60px;
  background-color: var(--primary--color-drawer);
  color: white;
  font-weight: 700;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 9999999;
}

.bannerButton {
  background-color: var(--primary--color-drawer);
  border: 1px solid white;
  color: white;
  padding: 8px 20px;
}

.banner svg {
  fill: white;
  color: white;
  border-color: white;
}
.modal-content {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  text-align: center;
}
div.modalTop {
  background-color: #212131;
  padding: 0 50px 50px;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.modalTop h2 {
  color: white;
  font-size: 40px;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 600;
  margin-bottom: 30px;
}

.modalTop p {
  color: white;
  font-size: 23px;
  display: flex;
  justify-content: center;
  width: 60%;
  border: 1px solid #212131;
}
.modalBottom {
  padding: 30px 0px 30px;
}
.blueText {
  color: #809cf8 !important;
  margin: 0px 8px;
}
.nextLevel {
  font-size: 28px;
  margin-bottom: 30px;
}
.xBtn {
  background-color: #212131;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  border: 1px solid #212131;
}
.xBtn button {
  background-color: transparent;
  border: none;
}
.completeInfo {
  border-radius: 25px;
  background: #5479f7;
  font-size: 18px;
  margin-bottom: 15px;
  padding: 10px;
}

.rocket {
  background: linear-gradient(to bottom, #212131 30%, white 30%);
}
.rocketBtn {
  border-radius: 15px;
  background-color: #5479f7;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: absolute;
  bottom: 50px;
  right: 50px;
  padding: 8px;
  display: flex;
  align-items: center;
  z-index: 4;
}
.stagesNumber {
  background-color: white;
  padding: 3px 8px;
  border-radius: 10px;
  color: #5479f7;
  font-size: 16px;
  margin-left: 4px;
  letter-spacing: 1px;
}
.drawerTitle {
  color: #000;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.drawerSubTitle {
  color: #959292;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-progress-bg {
  background-color: #5479f7 !important;
}
.stages {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.stageCircle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.stageTitle {
  font-size: 18px;
  margin-left: 10px;
}
.stageDone {
  color: #959292;
  font-size: 18px;
  text-decoration-line: line-through;
  margin-left: 10px;
}
.seeHow {
  border: none;
  background-color: transparent;
  color: #5479f7;
  font-size: 14px;
}
.infoinfo {
  display: flex;
  flex-direction: column;
}

.trialDays {
  border-radius: 10px;
  background: #e6ecfc;
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
}
@media (max-width: 576px) {
  .smallScreen {
    display: flex;
  }

  .paySection {
    margin: 20px auto 60px;
  }
  .infoSection {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    line-height: inherit;
  }

  .tittleAndSelect {
    margin: 50px 20px 10px;
  }

  /* info div */
  .csBsdA {
    display: flex;
    flex-direction: row;
  }

  .hpMzNb {
    width: 50%;
  }
  .khgCDx {
    margin-top: 0px;
  }
  .iZTeZa {
    margin-top: 0px;
  }

  .bSSyss {
    margin-bottom: 10px !important;
  }
  .hvYAPD {
    margin-top: 10px;
    margin-right: 0;
    font-size: 14px;
  }

  .modalTop {
    padding: 20px !important;
    border: 1px solid #212131;
    box-shadow: none;
  }
  .modalTop h2 {
    font-size: 20px;
    width: 100%;
  }
  .modalTop p {
    font-size: 16px;
    width: 100%;
  }
  .blueText {
    color: #809cf8;
    margin: 0px 2px;
  }
  .nextLevel {
    font-size: 20px;
  }
  .rocket {
    width: 100%;
    background: linear-gradient(to bottom, #212131 40%, white 30%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 1px solid #212131;
    box-shadow: none;
    justify-content: space-evenly;
  }
  .rocket img {
    width: 30%;
  }
  .modalBottom {
    padding: 0;
  }
}

.search-input {
  border-radius: 50px;
  background-color: #d6cfcf;
  z-index: 9999;
  left: 600px;
  top: 100px;
  position: absolute;
}

.dark-overlay {
  background-color: red;
  z-index: 1000;
}

.collapse-button {
  border: 1px solid transparent;
  z-index: 999;
  background-color: var(--neutral-color-strokes);
  color: var(--primary--color-drawer);
  font-size: 16px;
  right: -18px;
  bottom: 10px;
  height: 36px !important;
  width: 36px !important;
  position: absolute;
  border-radius: 50px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.collapse-button:hover {
  background-color: var(--neutral-color-strokes) !important;
  color: var(--primary--color-drawer) !important;
}

.icon-animation {
  transition: transform 0.3s ease;
}

.icon-animation:hover {
  transform: rotate(90deg); /* Rotación al hacer hover */
}

.layout-drawer-wrapper .ant-drawer-body {
  padding: 0px !important;
  margin-top: 24px;
}

.sider-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sider-top-part {
  display: flex;
  flex-direction: column;
}

.sider-bottom-part {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customer-list {
  z-index: 999999999;
  overflow-y: scroll;
  max-height: 70vh;
  margin-top: 250px;
  line-height: 1.3;
  border-radius: 12px;
  width: 100%;
  position: absolute;
  background-color: white;
  padding: 15px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  border-bottom: 1px solid lightgray;
}
.itemSelect:hover {
  cursor: pointer;
  background-color: rgb(0 0 0 / 5%);
}
