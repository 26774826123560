.contact-us-wrapper {
  gap: 12px;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  width: 100%;
  height: 136px;
  background-color: var(--primary-color-active);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.contact-us-popover-item {
    padding: 12px;
    gap: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.contact-us-popover-item-text {
    display: flex;
    flex-direction: column;
}
